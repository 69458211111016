<template>
    <div class="card_six" v-if="info">
        <img :src="info.logo ? info.logo : require('../../../../assets/images/card1_user.png')" alt="">
        <div class="card_con">
            <div class="name_job">
                <div class="name">{{info.name}}</div>
                <div class="jposi">{{info.position || '职位'}}</div>
            </div>
            <div class="tel_email">
                <div class="cus_img_val d-flex">
                    <img src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/crmRevision/ic_phone.png" alt="">
                    <div class="val">{{info.phone || '电话'}}</div>
                </div>
                <div class="cus_img_val d-flex">
                    <img src="https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/crmRevision/ic_email.png" alt="">
                    <div class="val">{{info.email || '邮箱'}}</div>
                </div>
            </div>
            <div class="companyname">{{info.companyName}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            info: Object
        }
    }
</script>

<style lang="scss" scoped>
    .card_six{
        width: 100%;
        height: 270px;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 6px 20px 0px rgba(25,25,25,0.06);
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        img{
            width: 270px;
            height: 100%;
            margin-left: calc(100% - 270px);
            object-fit: cover;
        }
        .card_con{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: url('../../../../assets/images/city.png');
            background-repeat: no-repeat;
            background-size: 252px 100%;
            padding: 54px 0 0 24px;
            .name_job{
                .name{
                    font-size: 24px;
                    font-weight: bold;
                    color: #3D3D3D;
                }
                .jposi{
                    font-size: 16px;
                    font-weight: 400;
                    color: #3D3D3D;
                }
            }
            .tel_email{
                margin-top: 38px;
                .cus_img_val{
                    align-items: center;
                    img{
                        width: 14px;
                        height: 14px;
                        margin: 0;
                    }
                    .val{
                        font-size: 14px;
                        font-weight: 400;
                        color: #B0B0B0;
                        margin-left: 6px;
                    }
                    &:first-child{
                        margin-bottom: 10px;
                    }
                }
            }
            .companyname{
                margin-top: 36px;
                font-size: 16px;
                font-weight: bold;
                color: #3D3D3D;
            }
        }
    }
</style>